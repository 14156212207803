import { createTheme } from '@material-ui/core/styles'

export const mainTheme = createTheme({
    palette: {
        primary: {
            main: '#14143C',
        },
        secondary: {
            main: '#f5222d',
        },
        error: {
            main: '#f5222d',
        },
        success: {
            main: '#7EAC55',
        },
    },
})
